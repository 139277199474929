@import "./colors";

.notification {
    &-drawer {
        .content {
            overflow-y: auto;
        }
        width: 500px;
        max-height: 432px;
        right: 20px;
        top: 70px;
        background-color: $white;
        display: flex;
        flex-direction: column;
        z-index: 99;
        position: absolute;
        font-size: 13px;
        box-shadow: 0px 1px 0px 1px rgb(237, 235, 235);
        -webkit-box-shadow: 0px 1px 0px 1px rgb(237, 235, 235);
        -moz-box-shadow: 0px 1px 0px 1px rgb(237, 235, 235);
        border-radius: 8px;
        &-header {
            padding: 16px;
            background-color: $white;
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            &-title {
                display: flex;
                align-items: center;
                width: 100%;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                height: 20px;
            }
            &-extra {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                height: 20px;
            }
        }
        &-panel {
            padding: 0px 16px 8px 16px;
            background-color: $white;
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            &-status {
                padding-left: 8px;
                display: flex;
                align-items: center;
                width: 100%;
                height: 20px;
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                color: $secondary;
            }
            &-read {
                display: flex;
                align-items: center;
                width: 100%;
                height: 20px;
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                color: $primary-50;
                justify-content: flex-end;
                cursor: pointer;
            }
        }
        &-body {
            padding: 0px 16px 0px 16px;
            width: 100%;
            height: 100%;
            &-container {
                padding-bottom: 16px;
                &-item {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    &:hover {
                        cursor: pointer;
                    }
                    &-left {
                        display: flex;
                        flex-direction: row;
                        width: 100%;
                        .status-unread,
                        .icon,
                        .title {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            line-height: 16px;
                            margin-right: 6px;
                        }
                        .title {
                            font-family: MuseoSans-700;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            color: $primary;
                        }
                        .status-read {
                            width: 8px;
                            margin-right: 8px;
                        }
                    }
                    &-right {
                        display: flex;
                        justify-content: flex-end;
                        width: 100%;
                    }
                    .time {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 10px;
                        color: $primary;
                        height: 24px;
                    }
                }
                &-desc {
                    cursor: default !important;
                    display: flex;
                    align-items: center;
                    padding-left: 46px;
                    width: 100%;
                    .description {
                        color: $primary;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 16px;
                        .name {
                            color: $primary-50;
                        }
                        .default {
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                        }
                    }
                }
                &:hover {
                    border-radius: 8px;
                    cursor: pointer;
                }
                &-no-ancor {
                    .name {
                        &:hover {
                            cursor: pointer;
                        }
                    }
                    &:hover {
                        border-radius: 8px;
                        cursor: unset;
                    }
                }
            }
        }
        .no-more {
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: MuseoSans-500;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            color: $secondary;
        }
    }
}
