@import "./colors";

.ant-notification {
  .snackbar {
    min-width: 500px;
    // max-height: 48px;
    display: flex;
    align-items: center;
    padding: 16px;
    box-shadow: 0px 8px 32px 2px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    .ant-notification-notice {
      &-message {
        margin: 0 0 0 40px;
      }
      &-icon {
        align-items: center;
        justify-content: center;
      }
    }
    .ant-notification-notice-close {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-success {
      background: $white;
      .ant-notification-notice {
        &-icon,
        &-message,
        &-close {
          color: $primary;
        }
      }
    }
    &-info {
      background: #e3f6fd;
      .ant-notification-notice {
        &-icon,
        &-message,
        &-close {
          color: #297fb9;
        }
      }
    }
    &-warning {
      background: #fcf7e2;
      .ant-notification-notice {
        &-icon,
        &-message,
        &-close {
          color: #d01a22;
        }
      }
    }
    &-error {
      background: #ffebee;
      .ant-notification-notice {
        &-icon,
        &-message,
        &-close {
          color: #d01a22;
        }
      }
    }
  }
}
