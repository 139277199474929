@import "./colors";
@import "./pagination";
@import "./tables";
@import "./modals";
@import "./datepicker";

.crm-developer-business {
  min-height: 100vh;
  .ant-layout-header {
    padding: unset !important;
  }
  &-header {
    .ant-input {
      min-height: 22px !important;
    }
    .ant-input-affix-wrapper {
      min-height: 40px !important;
      box-shadow: $dark !important;
      &-lg {
        padding: 8px 20px !important;
      }
    }

    .ant-input-prefix {
      font-size: 16px !important;
      color: #31373d !important;
      margin-right: 16px !important;
    }
    .ant-select-single .ant-select-selector .ant-select-selection-search-input {
      width: 100% !important;
      height: 40px !important;
      border-radius: $border-radius-base !important;
      font-size: 14px !important;
      color: #738492 !important;
    }
    background-color: $white;
    border-bottom: 2px solid rgba(40, 41, 61, 0.04);

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 72px;
    &-left {
      margin-left: 24px;
    }
    &-right {
      margin-right: 24px;
      .notification {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        color: $secondary;
        cursor: pointer;
        .ant-badge-count {
          font-family: MuseoSans-700;
          font-style: normal;
          font-weight: normal;
          font-size: 8px;
          padding: unset;
        }
      }
      .profile {
        cursor: pointer;
      }
    }
    .profile-container {
      display: flex;
      justify-content: center;
      align-items: center;

      .divider {
        width: 2px;
        background-color: $dark;
        margin: 0 24px 0 30px;
        height: 40px;
      }
    }
  }
}
.dropdown-profile {
  background-color: $white;
  margin-top: -4px;
  min-width: 220px;
  width: 100%;
  border-radius: $border-radius-base;
  &-header {
    display: flex;
    flex-direction: row;
    &-name {
      color: $primary;
      font-family: MuseoSans-700;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
    }
    &-detail {
      display: flex;
      justify-content: center;
      flex-direction: row;
      align-items: center;
      color: $secondary;
      font-family: MuseoSans-500;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      .divider {
        width: 1px;
        background-color: $secondary;
        margin: 0 8px 0 8px;
        height: 16px;
      }
    }
  }
  &-logout {
    color: $error;
    font-family: MuseoSans-500;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
  }
}

.ant-layout {
  &-content-main {
    background-color: $white;
    padding: 20px;
    min-height: calc(100vh - 72px);
  }
}
.d-none {
  display: none !important;
}

.pr-1 {
  padding-right: 0.5rem;
}
.pr-2 {
  padding-right: 1rem;
}
.pl-1 {
  padding-left: 0.5rem;
}
.pl-2 {
  padding-left: 1rem;
}
.my-1 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.my-2 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.py-1 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-2 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.text{
  &-primary{
    color: $primary;
  }
  &-primary-50{
    color: $primary-50;
  }
  &-warning{
    color: $warm;
  }
  &-info{
    color: $info;
  }
  &-danger{
    color: $error;
  }
  &-success{
    color: $won;
  }
  &-dark{
    color: $dark-90;
  }
  &-center{
    text-align: center;
  }
  &-left{
    text-align: left;
  }
  &-right{
    text-align: right;
  }
  &-justify{
    text-align: justify;
  }
}