@import "./colors";

.ant-notification {
  .toast {
    min-width: 500px;
    min-height: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    padding: 16px;
    box-shadow: 0px 8px 32px 2px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    .ant-notification-notice {
      &-message {
        margin: unset;
      }
    }
    &-success {
      background: $white;
      .ant-notification-notice {
        &-icon,
        &-message,
        &-close {
          color: $primary;
        }
      }
    }
    &-info {
      background: #e3f6fd;
      .ant-notification-notice {
        &-icon,
        &-message,
        &-close {
          color: #297fb9;
        }
      }
    }
    &-warning {
      background: #fcf7e2;
      .ant-notification-notice {
        &-icon,
        &-message,
        &-close {
          color: #d01a22;
        }
      }
    }
    &-error {
      background: #ffebee;
      .ant-notification-notice {
        &-icon,
        &-message,
        &-close {
          color: #d01a22;
        }
      }
    }
  }
  .reminder {
    padding: 18px;
    background-color: $white;
    min-width: 376px !important;
    box-shadow: 0px 8px 32px 2px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    .ant {
      &-notification {
        &-notice {
          &-with {
            &-icon {
              align-items: center;
            }
          }
          &-message {
            margin-left: 30px;
            font-family: MuseoSans-700;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
          }
          &-description {
            margin-left: 30px;
            font-family: MuseoSans-500;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            .type {
              font-family: MuseoSans-700;
            }
            .name {
              color: $primary-50;
              font-family: MuseoSans-700;
            }
          }
          &-close {
            font-family: MuseoSans-700;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
          }
        }
      }
    }
  }
  .import-leads {
    padding: 18px;
    background-color: $white;
    min-width: 376px !important;
    box-shadow: 0px 8px 32px 2px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    .ant {
      &-notification {
        &-notice {
          &-with {
            &-icon {
              align-items: center;
            }
          }
          &-message {
            margin-left: 30px;
            font-family: MuseoSans-700;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
          }
          &-description {
            margin-left: 30px;
            font-family: MuseoSans-500;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            .type {
              font-family: MuseoSans-700;
            }
            .name {
              color: $primary-50;
              font-family: MuseoSans-700;
              cursor: pointer;
            }
            .filename {
              color: $primary;
              font-style: normal;
              font-weight: 600;
              font-size: 12px;
            }
          }
          &-close {
            margin-top: 16px;
            font-family: MuseoSans-700;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
          }
        }
      }
    }
  }
}
