@import "./colors";
body {
    .ant-pagination {
        &-prev,
        &-next,
        &-item,
        &-jump-next,
        &-jump-prev {
            border: 1px solid $dark;
            border-radius: 0;
            margin: 0;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            background-color: $white;
            border-right-width: 0;
            width: 32px;
            height: 32px;

            &-link {
                width: 32px;
            }

            &:hover,
            &:focus {
                border: 1px solid rgba($color: $dark, $alpha: 0.1);
                background-color: $primary-50;
                border-right-width: 0;

                a,
                .ant-pagination-item-container .ant-pagination-item-link-icon,
                .ant-pagination-item-container .ant-pagination-item-ellipsis,
                .ant-pagination-item-link {
                    color: $white;
                }
            }
        }

        &-prev {
            border-right-width: 0;
            border-top-left-radius: $border-radius-base;
            border-bottom-left-radius: $border-radius-base;
        }

        &-next {
            border-right-width: 1px;
            border-top-right-radius: $border-radius-base;
            border-bottom-right-radius: $border-radius-base;

            &:hover {
                border-right-width: 1px;
            }
        }

        &-next,
        &-prev {
            .ant-pagination-item-link {
                border-radius: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                border-width: 0;
                background-color: transparent;
            }
        }

        &-item {
            transition: all ease 0.2s;

            &-active {
                border: 1px solid rgba($color: $primary-50, $alpha: 0.5);
                background-color: $primary-50;

                a {
                    color: $white !important;
                }

                &:hover {
                    border: 1px solid rgba($color: $primary-50, $alpha: 0.5);
                    background-color: $primary-50;

                    a,
                    span {
                        color: $white;
                    }
                }
            }
        }
    }
}
