//primary
$primary-10: #c1d6ff;
$primary-20: #88a5e1;
$primary-30: #5982d5;
$primary-40: #3a6acd;
$primary-50: #2951a3;
$primary-60: #254993;
$primary-70: #1d3a74;
$primary-80: #152a55;
$primary-90: #0d1a35;

//secondary
$secondary-10: #fdf6e4;
$secondary-20: #fcedc9;
$secondary-30: #fae4af;
$secondary-40: #f9db94;
$secondary-50: #f7d279;
$secondary-60: #debd6d;
$secondary-70: #c6a861;
$secondary-80: #ad9355;
$secondary-90: #947e49;
$secondary-100: #7c693d;

//fuschia
$fuschia-10: #fcdfee;
$fuschia-20: #f9bedc;
$fuschia-30: #f59ecb;
$fuschia-40: #f27db9;
$fuschia-50: #ef5da8;
$fuschia-60: #d75497;
$fuschia-70: #bf4a86;
$fuschia-80: #8f3865;
$fuschia-90: #602543;
$fuschia-100: #301322;

//pink
$pink-10: #fbe8fc;
$pink-20: #f7d2f9;
$pink-30: #f4bbf7;
$pink-40: #f0a5f4;
$pink-50: #ec8ef1;
$pink-60: #d480d9;
$pink-70: #bd72c1;
$pink-80: #a563a9;
$pink-90: #8e5591;
$pink-100: #764779;

//purple
$purple-10: #e0daeb;
$purple-20: #c1b4d7;
$purple-30: #a18fc3;
$purple-40: #8269af;
$purple-50: #63449b;
$purple-60: #593d8c;
$purple-70: #4f367c;
$purple-80: #45306d;
$purple-90: #3b295d;
$purple-100: #32224e;

//maroon
$maroon-10: #ecd2d8;
$maroon-20: #d9a6b1;
$maroon-30: #c7798b;
$maroon-40: #b44d64;
$maroon-50: #a1203d;
$maroon-60: #911d37;
$maroon-70: #811a31;
$maroon-80: #71162b;
$maroon-90: #611325;
$maroon-100: #51101f;

//neutral
$neutral-10: #ffffff;
$neutral-20: #e7e8eb;
$neutral-30: #cfd1d7;
$neutral-40: #b6bac2;
$neutral-50: #9ea3ae;
$neutral-60: #868d9a;
$neutral-70: #6e7686;
$neutral-80: #565f72;
$neutral-90: #3d485d;
$neutral-100: #0d1a35;

//state
$success: #38baa7;
$success-30: #71d194;
$success-40: #e6f7ec;
$success-50: #1ebb66;
$success-second: #ebf8f6;
$error: #d70c24;
$error-second: #fbe7e9;
$error-50: #ffebee;
$warning: #fecf01;
$warning-second: #fffae6;
$disable: #cfd1d7;

//text
$white: #ffffff;
$black: #000000;
$primary: #0d1a35;
$secondary: #868d9a;
$link: #2951a3;
$light: #f5f5f5;
$light-10: #fafafa;
$light-30: #f0f0f0;
$light-40: #f4f4f4;
$dark: #ecedee;
$dark-40: #738492;
$dark-50: #5b7080;
$dark-90: #20282e;
$warm: #e69a1b;
$info: #2b6ed2;
$won: #13ac5c;

//product
$premier: #ffb200;
$featured: #00beb3;
$properi-baru: #ff4f00;
$whatsapp: #00994f;
$verified: #2951a3;

//radius border
$border-radius-base: 8px;
$border-radius-xl: 16px;
$border-radius-large: 100px;

//alert
$success-color: #4dc67d;
$warning-color: #e69a1b;
$error-color: #f85254;
$info-color: #2b6ed2;

//agent
$bg-agent: #f6e9ec;
