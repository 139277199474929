@import "./colors";

.datepicker {
    &-report {
        &-no-info {
            width: 32px !important;
            height: 32px !important;
            border: 1px solid $primary-50;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-left: 8px;
            border-radius: 8px;
            cursor: pointer;
            background-color: $primary-50;
            &:hover {
                background-color: $primary-20;
                border: 1px solid $primary-20;
            }
            .ant-picker-input,
            .ant-picker-input-active,
            .ant-picker-range-separator {
                display: none;
            }
            .ant-picker-suffix {
                margin-left: 0;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        &-with-info{
            width: auto;
            height: auto;
            min-height: 32px;
            border: 1px solid $primary-50;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-left: 8px;
            border-radius: 8px;
            cursor: pointer;
            background-color: $primary-50;
            &:hover {
                background-color: $primary-20;
                border: 1px solid $primary-20;
            }
            .ant-picker-input,
            .ant-picker-input-active,
            .ant-picker-range-separator {
                display: none;
            }
            .ant-picker-suffix {
                margin-left: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                &::after{
                    content: "Download Report";
                    color: $white;
                    margin-left: 4px;
                }
            }
        }
        &-dropdown {
            background-color: $white;
            padding: 0;
            top: 128px !important;
            @media screen and (max-width: 1393px) {
                left: 810px !important;
            }
            @media screen and (min-width: 1393px) {
                left: 63% !important;
            }
            @media screen and (min-width: 1740px) {
                left: 66% !important;
            }
            @media screen and (min-width: 1856px) {
                left: 69% !important;
            }
            .ant-picker-panel-container {
                border-radius: 8px !important;
                border: unset !important;
            }
            .ant {
                &-picker {
                    &-date {
                        &-panel {
                            border: unset !important;
                            background-color: $light-10 !important;
                        }
                    }
                    &-panel {
                        border: unset !important;
                        background-color: $light-10 !important;
                    }
                    &-range {
                        &-arrow {
                            display: none;
                        }
                    }
                    &-header {
                        border: unset !important;
                        background-color: $light-10 !important;
                    }
                    &-body {
                        border: unset !important;
                        background-color: $light-10 !important;
                    }
                    &-footer {
                        border-radius: 8px;
                        border: unset !important;
                        background-color: $white !important;
                        &-extra {
                            border: unset !important;
                            background-color: $light-10 !important;
                            border-bottom-left-radius: 8px;
                            border-bottom-right-radius: 8px;
                            width: 100%;
                            padding: 24px;
                            display: flex;
                            flex-direction: row;
                        }
                    }
                }
            }
            .ant-picker-panel-container .ant-picker-panel {
                border: unset;
            }
        }
        &-dropdown::before {
            content: "Set Report Date";
            width: 100%;
            background-color: $white !important;
            height: 24px;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 24px 16px;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            border: 1px solid rgba(40, 41, 61, 0.04);
            border-bottom-color: transparent;
        }
        &-button {
            width: 100%;
            border-radius: 8px;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            margin: 2px;
            &-download {
                background-color: $primary-50;
                color: $white;
                border: unset;
                &:hover {
                    background-color: $white;
                    border: 1px solid $primary-50;
                }
            }
            &-cancel {
                background-color: $white;
                color: $primary-50;
                border: 1px solid $primary-50;
                &:hover {
                    background-color: $dark;
                }
            }
        }
    }
}
