@import "./colors";

.badge {
  border-radius: $border-radius-base;
  text-align: center;
  color: #e6e7e8;
  border: unset;
  font-family: MuseoSans-700;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
}

.badge-lead-type {
  background: $primary-10;
  color: $primary;
}

.badge-hot {
  background: $error;
  color: $white;
  border-radius: $border-radius-large;
}

.badge-new {
  background: $dark-50;
  color: $white;
  border-radius: $border-radius-large;
}

.badge-warm {
  background: $warm;
  color: $white;
  border-radius: $border-radius-large;
}

.badge-cold {
  background: $info;
  color: $white;
  border-radius: $border-radius-large;
}

.badge-won {
  background: $won;
  color: $white;
  border-radius: $border-radius-large;
}

.badge-unread {
  background: $primary-10;
  color: $primary-50;
  border-radius: $border-radius-large;
}

.badge-completed {
  background: $success-40;
  color: $success-50;
  border-radius: $border-radius-large;
}

.badge-default {
  min-width: 40px;
  width: 40px;
  color: $primary;
}

.badge-agent {
  background-color: $bg-agent;
  color: $maroon-50;
  font-family: MuseoSans-700;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}

.badge-cross-sell {
  background: $primary-10;
  color: $primary;
  &-origin{
    min-width: 70px;
  }
  &-manual{
    min-width: 70px;
  }
  &-automation{
    min-width: 90px;
  }
}