@import "./colors";

.ui-modal {
    .optional {
        font-family: MuseoSans-500;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        color: $dark-40;
    }
    box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
    .ant-modal {
        &-header {
            border-radius: 8px;
            padding: 16px;
        }
        &-title {
            font-family: MuseoSans-700;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            color: $primary;
        }
        &-content {
            border-radius: 8px;
        }
        &-body {
            padding: 16px;
            min-height: 114px;
            .container {
                display: flex;
                flex-direction: column;
                &-title {
                    font-family: MuseoSans-700;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                }
                &-description {
                    font-family: MuseoSans-500;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    color: $secondary;
                }
            }
        }
        &-footer {
            .ant-btn {
                min-width: 94px;
                border-radius: 8px;
            }
        }
    }
}
.modal {
    &-form {
        &-leads {
            top: 5em;
            .input-datepicker {
                min-height: 40px;
                border-radius: 8px;
                border: 1px solid #ced3d7;
                width: 100%;
            }
            .ant-input-group-wrapper {
                .ant-input-group-addon {
                    border-radius: 8px 0 0 8px;
                    color: $secondary;
                }
                .ant-input-affix-wrapper {
                    min-height: 30px !important;
                    border-radius: 0px 8px 8px 0px !important;
                }
            }
            &-import {
                .template {
                    border-radius: 8px;
                    height: 155px;
                    width: 95%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: auto;
                    .content {
                        width: 293px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        text-align: center;
                        .title {
                            font-family: MuseoSans-700;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            color: $primary;
                        }
                        .action {
                            color: $primary-50;
                            cursor: pointer;
                        }
                        .desc {
                            font-family: MuseoSans-500;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            color: $secondary;
                        }
                    }
                }
                .upload {
                    .ant-upload-drag {
                        .ant-upload-btn {
                            padding: unset;
                            background-color: $white;
                            &:hover {
                                background-color: $light;
                            }
                        }
                    }
                    &-container {
                        border: 1px dashed #2951a3;
                        height: 155px;
                        width: 95%;
                        margin: auto;
                        .content {
                            padding: unset;
                            margin: unset;
                            height: 100%;
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            text-align: center;
                            .title {
                                font-family: MuseoSans-700;
                                font-style: normal;
                                font-weight: 400;
                                font-size: 14px;
                                color: $primary;
                            }
                            .action {
                                color: $primary-50;
                                cursor: pointer;
                            }
                            .desc {
                                font-family: MuseoSans-500;
                                font-style: normal;
                                font-weight: 400;
                                font-size: 12px;
                                color: $secondary;
                            }
                        }
                    }
                }
                .file {
                    border: 1px dashed #2951a3;
                    border-radius: 8px;
                    height: 155px;
                    width: 95%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: auto;
                    .content {
                        width: 293px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        text-align: center;
                        .filename {
                            font-family: MuseoSans-700;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            color: $primary;
                        }
                        .remove {
                            font-family: MuseoSans-500;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            color: $primary-50;
                            cursor: pointer;
                        }
                    }
                }
                .caption {
                    margin-top: 16px;
                    display: flex;
                    flex-direction: column;
                    .info {
                        font-family: MuseoSans-500;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                    }
                    .action {
                        font-family: MuseoSans-500;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        color: $primary-50;
                        cursor: pointer;
                    }
                }
            }
        }
        &-contacted,
        &-leads,
        &-assign,
        &-qualification,
        &-close {
            .ant-input-group-wrapper {
                .ant-input-group-addon {
                    border-radius: 8px 0 0 8px;
                    color: $secondary;
                }
            }
            .ant-input {
                min-height: 40px;
                border-radius: 8px;
                border: 1px solid #ced3d7;
                &.invalid {
                    border: 1px solid #fcf7e2;
                    background-color: #fcf7e2;
                }
                &.error {
                    border: 1px solid #dd2329;
                    background: $error;
                }
            }

            .ant-input-affix-wrapper {
                padding: 0px 8px 0px 8px !important;
                min-height: 30px !important;
                border-radius: 8px !important;
            }

            .input-right-addon {
                .ant-input-group {
                    input.ant-input {
                        min-height: 40px;
                        border-radius: 8px 0 0 8px;
                    }
                    .ant-input-group-addon {
                        background-color: #ecedee;
                        min-width: 55px;

                        &:last-child {
                            border-radius: 0 8px 8px 0;
                        }
                    }
                }
            }

            .input-left-addon {
                .ant-input-group {
                    input.ant-input {
                        min-height: 40px;
                        border-radius: 0 8px 8px 0;
                    }
                    .ant-input-group-addon {
                        background-color: #ecedee;
                        min-width: 55px;

                        &:first-child {
                            border-radius: 8px 0 0 8px;
                        }
                    }
                }
            }

            .ant-input-group-wrapper.invalid {
                .ant-input {
                    border: 1px solid #f2994a;
                    background-color: #fcf7e2;
                }

                .ant-input-group-addon {
                    background: #f7e9b4;
                    border: 1px solid #f2994a;
                }
            }

            .ant-input-group-wrapper.error-bulk {
                .ant-input {
                    border: 1px solid #dd2329;
                    background-color: $error;
                }

                .ant-input-group-addon {
                    background: $error;
                    border: 1px solid #dd2329;
                }
            }

            .ant-input[disabled] {
                color: rgba(0, 0, 0, 0.25);
                background-color: $dark;
                cursor: not-allowed;
                opacity: 1;
            }

            .ant-form-item-explain {
                min-height: 22px !important;
            }

            .ant-select-selector {
                min-height: 40px;
                border-radius: 8px !important;
                display: flex;
                align-items: center;
                justify-content: flex-start;

                .ant-select-selection-overflow-item {
                    border-radius: 0px;
                }

                .ant-select-selection-search {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                }
            }
        }
        &-information {
            height: 50px;
            margin-top: unset;
            display: flex;
            align-items: center;
        }
    }
}
.option-lead-status {
    background-color: rebeccapurple !important;
}
.modalupload {
    .image {
        cursor: pointer;
        width: 100%;
        max-width: 300px;
        height: auto;
    }
    /* The Modal (background) */
    .modal {
        position: fixed; /* Stay in place */
        z-index: 1; /* Sit on top */
        // padding-top: 20px; /* Location of the box */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background-color: rgb(0, 0, 0); /* Fallback color */
        background-color: rgba(0, 0, 0, 0.9); /* Black w/ opacity */
    }

    .view-photo {
        width: 100%;
        height: 100%;
        text-align: center;
        .button {
            &-reupload {
                margin-top: 16px;
                margin-bottom: 16px;
                border-radius: 8px;
            }
        }
    }

    .img-proof-visit {
        &:hover {
            cursor: pointer;
            // -webkit-filter: blur(1px);
            filter: blur(0.5px);
        }
    }
    /* Modal Content (image) */
    .modal-content {
        margin: 200px auto;
        display: block;
        width: 30%;
        max-width: 700px;
        // margin-top: -50px;
    }

    /* Caption of Modal Image */
    .caption {
        margin: auto;
        display: block;
        width: 80%;
        max-width: 700px;
        text-align: center;
        color: #ccc;
        padding: 10px 0;
        height: 150px;
    }

    /* Add Animation */
    .modal-content,
    .caption {
        -webkit-animation-name: zoom;
        -webkit-animation-duration: 0.6s;
        animation-name: zoom;
        animation-duration: 0.6s;
    }

    @-webkit-keyframes zoom {
        from {
            -webkit-transform: scale(0);
        }
        to {
            -webkit-transform: scale(1);
        }
    }

    @keyframes zoom {
        from {
            transform: scale(0);
        }
        to {
            transform: scale(1);
        }
    }

    /* The Close Button */
    .close {
        position: absolute;
        top: 20px;
        right: 75px;
        color: #f1f1f1;
        font-size: 40px;
        font-weight: bold;
        transition: 0.3s;
    }

    .close:hover,
    .close:focus {
        color: #bbb;
        text-decoration: none;
        cursor: pointer;
    }

    /* 100% Image Width on Smaller Screens */
    @media only screen and (max-width: 700px) {
        .modal-content {
            width: 100%;
        }
    }
    .ant-modal {
        &-header {
            border-radius: 8px;
            padding: 16px;
        }
        &-title {
            font-family: MuseoSans-700;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            color: $primary;
        }
        &-content {
            border-radius: 8px;
        }
        &-body {
            padding: 36px 36px 0px 36px;
            min-height: 155px;
            .container {
                display: flex;
                flex-direction: column;
                &-title {
                    font-family: MuseoSans-700;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                }
                &-description {
                    font-family: MuseoSans-500;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    color: $secondary;
                }
            }
        }
        &-footer {
            .ant-btn {
                min-width: 94px;
                border-radius: 8px;
            }
        }
    }
    .ant-upload-picture-card-wrapper {
        .ant-upload-list {
            .ant-upload {
                background-color: #fbfbfb;
                width: 100%;
                border-radius: 10px;
            }
            .ant-upload-list-picture-card-container {
                width: 100%;
                height: 250px;
                .ant-upload-list-item-list-type-picture-card {
                    border: unset;
                    .ant-upload-list-item-info {
                        .ant-upload-span {
                            background-color: transparent;
                        }
                    }
                }
            }
        }
    }
    .ant-upload.ant-upload-select-picture-card {
        height: 100%;
        border: 1px dashed $primary-50;
    }

    .ant-upload {
        padding: 20px;
    }
}
