@import "./colors.scss";

.ant-table {
  background-color: $white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  overflow: hidden;
  border: 1px solid #ecedee;

  .ant-table-thead {
    .ant-table-cell {
      padding: 8.5px;
    }
  }
  .ant-table-body{
    .ant-table-cell {
      padding: 16px 16px 16px 8.5px;
    }
  }

  tr {
    th {
      background-color: $light;
      font-family: MuseoSans-700;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 12px;
      &.ant-table-column-has-sorters:hover {
        background: $dark;
      }
    }
    td {
      font-family: MuseoSans-500;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      &.ant-table-column-has-sorters:hover {
        background: $dark-40;
      }
    }
  }

  .table-cell {
    &-export,
    &-setting {
      font-size: 18px;
    }

    &-export {
      color: $primary-50;
    }
  }
}

.container-page {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  &-number{
    display: flex;
    flex-direction: row;
    &-show{
      justify-content: center;
      .ant-select-selector{
        border: 0.5px solid $dark;
        border-radius: 8px !important;
        .ant-select-selection-item{
          line-height: 32px;
          padding-left: 6px !important;
        }
      }
    }
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
    padding: unset !important;
  }
}
.ant-pagination-item,
.ant-pagination-prev,
.ant-pagination-next {
  border: 1px solid #738492;
  margin-right: 0;
  border-radius: 0px;
}

.ant-pagination-item-active {
  font-weight: 500;
  font-size: 14px;
  background: #297fb9;
}

.ant-pagination-item-active a {
  color: #fff;
}

.ant-select {
  border-radius: 8px;
}

.ant-table-sticky-scroll-bar {
  height: unset !important;
  background-color: unset !important;
  border-radius: unset !important;
}