@import "./colors";

.sidebar {
  max-width: 72px !important;
  min-width: 72px !important;
  width: 72px !important;
  background-color: $primary-50;
  &-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 16px 20px 16px;
    img {
      height: 40px;
      width: 40px;
    }
  }
  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border-right: unset;
  }
  .ant-menu {
    background-color: $primary-50 !important;
    &-item {
      display: block;
      background-color: transparent !important;
      .ant-menu-item-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
          color: $primary-10 !important;
          background-color: $primary-60;
          border-radius: $border-radius-base;
        }
      }
      &-selected {
        background-color: transparent !important;
        .ant-menu-item-icon {
          background-color: $primary-60 !important;
          border-radius: $border-radius-base;
        }
      }
    }
    &-inline-collapsed {
      .ant-menu-item {
        .ant-menu-item-icon {
          margin-left: -12px;
          width: 40px !important;
          height: 40px !important;
        }
      }
    }
    &-submenu {
      &-title {
        display: block;
        margin-left: -12px;
        background-color: transparent !important;
        .ant-menu-item-icon {
          display: block;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 40px !important;
          height: 40px !important;
        }
        .ant-menu-item-icon::after {
          position: absolute;
          right: 0;
          margin-right: 6px;
          height: 26px;
          width: 14px;
        
          content: "";
          background-image: url("./../icons/more.svg") !important;
        }
      }
      &-selected {
        background-color: transparent !important;
        .ant-menu-item-icon {
          background-color: $primary-60 !important;
          border-radius: $border-radius-base;
        }
      }
      &-popup {
        background-color: transparent !important;
      }
    }
  }
}

.ant-menu {
  &-sub {
    width: 227px;
    background-color: $primary-70 !important;
    box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16);
    border-radius: $border-radius-base !important;
    .ant-menu-item-only-child {
      color: $white;
    }
    .header-submenu {
      pointer-events: none;
      border-bottom: 1px solid $primary-60;
      font-family: MuseoSans-500 !important;
      font-style: normal;
      font-weight: normal;
      height: 40px !important;
      font-size: 12px;
    }
  }
  &-item {
    display: flex;
    align-items: center;
    color: $white;
    font-family: MuseoSans-700;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    &:hover {
      color: $primary-10 !important;
      background-color: $primary-60;
    }
    &-selected {
      background-color: $primary-60 !important;
    }
  }
  &-title-content {
    font-family: MuseoSans-700;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    height: 40px !important;
  }
}
.ant-tooltip-placement-right {
  .ant-tooltip-content {
    background-color: $primary-70;
    min-width: 134px;
    min-height: 40px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    .ant-tooltip-arrow {
      display: none;
      &-content {
        display: none;
        background-color: transparent;
      }
      background-color: transparent;
    }
    .ant-tooltip-inner {
      background-color: transparent;
    }
  }
  padding: 0px;
}
